


































import Vue from 'vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  data() {
    return {
      cardTypeEnum: {
        1: '身份证',
        2: '护照',
        3: '港澳居民来往内地通行证',
        4: '台湾居民来往内地通行证',
      },
      visitors: [],
    };
  },
  async asyncData(ctx) {
    const res: any[] = await Promise.all([ctx.$axios.post(`/api/visitor/list-by-current`)]);

    const [visitorRes] = res;

    let visitors = [];
    if (visitorRes.code === ResponseCode.SUCCESS) {
      visitors = visitorRes.data || '';
    }

    return {
      visitors,
    };
  },
  methods: {
    async fetchVisitorList() {
      const res: any = await this.$axios.post(`/api/visitor/list-by-current`);
      console.log(res);
      if (res.code === ResponseCode.SUCCESS) {
        this.visitors = res.data || '';
      }
    },
    handleViewVistor(id: number) {
      console.log(id);
    },
    async handleEditVistor(visitor: Record<string, any>) {
      console.log(visitor);
      await this.$store.commit('visitor/setVisitorDetail', visitor);
      this.$router.push(`/manager/visitor-edit/${visitor.id}`);
    },
    async handleDelVistor(id: number) {
      const res: any = await this.$axios.post(`/api/visitor/delete`, { id });
      if (res.code === ResponseCode.SUCCESS) {
        this.$message.success(`删除成功!`);
        this.fetchVisitorList();
      }
    },
  },
});
