































import Vue from 'vue';
import { ResponseCode } from '~/util/constant';
import {getXOssProcess} from "~/util/common";

export default Vue.extend({
  components: {},
  async asyncData(ctx) {
    const pageNum = 1;
    const pageSize = 12;

    const ticketRes: any = await ctx.$axios.post(
      '/api/userFollowApi/myBeFollowedList',
      {
        pageNum,
        pageSize,
      }
    );
    let userList = {};

    if (ticketRes.code === ResponseCode.SUCCESS) {
      userList = ticketRes.data;
      console.log(userList);
    }

    return {
      pageNum,
      pageSize,
      userList,
    };
  },
  data(): any {
    return {
      pageNum: 1,
      pageSize: 10,
    };
  },
  mounted() {},
  methods: {
    getXOssProcess,
    toUser(id: number) {
      window.open(`/expouser/${id}.html`);
    },
    // 换页
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
    async handleGetList(val: number) {
      const res = await this.$axios.post(
        `/api/userFollowApi/myBeFollowedList`,
        {
          pageNum: val,
          pageSize: 12,
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.$set(this, 'userList', {
          data: res.data,
        });
      }
    },
  },
  head() {
    return {
      title: '关注的用户-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
